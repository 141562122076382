import React from "react"
import NavBar from "../components/navbar"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  
  if (isRootPath) {
    // トップページ
    return (
      <>
        <header className="global-header">
          <NavBar />
          <StaticImage
            className="global-header-image"
            layout="constrained"
            alt=""
            src="../images/header.jpg"
            quality={100}
            maxWidth={1060}
          />
        </header>
        <div className="global-wrapper-root" data-is-root-path={isRootPath}>
          <main>{children}</main>
        </div>
        <Footer />
      </>
    )
  } else if (location.pathname.match("/promotion")) {
    // プロモーション用
    return (
      <>
        <header className="global-header">
          <NavBar />
        </header>
        <div
          className="global-wrapper-promotion"
          data-is-root-path={isRootPath}
        >
          <main>{children}</main>
        </div>
        <Footer />
      </>
    )
  } else if (location.pathname.match("/category")) {
    // 製品カテゴリー用
    return (
      <>
        <header className="global-header">
          <NavBar />
          <div className="global-header-image">
            <StaticImage
              layout="constrained"
              alt=""
              src="../images/page-header.png"
              quality={100}
            />
            <p>{title}</p>
          </div>
        </header>
        <div className="global-wrapper-root" data-is-root-path={isRootPath}>
          <main>{children}</main>
        </div>
        <Footer />
      </>
    )
  } else if (location.pathname.match("/page/news")) {
    // ニュース記事用
    return (
      <>
        <header className="global-header">
          <NavBar />
          <div className="global-header-image">
            <StaticImage
              layout="constrained"
              alt=""
              src="../images/page-header.png"
              quality={100}
            />
            <p>お知らせ</p>
          </div>
        </header>
        <div className="global-wrapper" data-is-root-path={isRootPath}>
          <main>{children}</main>
        </div>
        <Footer />
      </>
    )
  } else {
    return (
      <>
        <header className="global-header">
          <NavBar />
        </header>
        <div className="global-wrapper" data-is-root-path={isRootPath}>
          <main>{children}</main>
        </div>
        <Footer />
      </>
    )
  }
}

export default Layout
