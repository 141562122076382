import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer>
      <div className="footer_info_sections">
        <div className="footer_info_sections_items">
          <Link to="/" aria-label="ホーム">
            <StaticImage
              layout="constrained"
              src="../images/logo-2.png"
              alt="Logo"
              height={60}
            />
          </Link>
          <h1>アイゼック株式会社</h1>
          <p>
            〒１８３―００４５
            <br />
            東京都府中市美好町２－９－２
            <br />
            TEL：０４２-３６９-２０４１
            <br />
            FAX：０４２-３６９-２０４２
          </p>
        </div>
        <div className="footer_info_sections_items">
          お知らせ
          <ul>
            <li>
              <Link to="/category/news" aria-label="お知らせ">
                お知らせ
              </Link>
            </li>
            <li>
              <Link to="/category/blog" aria-label="技術ブログ">
                技術ブログ
              </Link>
            </li>
            <li>
              <Link to="/category/case" aria-label="技術ブログ">
                事例紹介
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer_info_sections_items">
          製品
          <ul>
            <li>
              <Link to="/category/camera" aria-label="監視カメラ">
                監視カメラ
              </Link>
            </li>
            <li>
              <Link to="/category/recorder" aria-label="レコーダー">
                レコーダー
              </Link>
            </li>
            <li>
              <Link to="/category/transfer" aria-label="画像伝送装置">
                画像伝送装置
              </Link>
            </li>
            <li>
              <Link to="/category/thermo" aria-label="非接触型測温計">
                非接触型測温計
              </Link>
            </li>
            <li>
              <Link to="/category/miscs" aria-label="周辺機器">
                周辺機器
              </Link>
            </li>
            <li>
              <Link to="/category/software" aria-label="ソフトウェア">
                ソフトウェア
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer_info_sections_items">
          その他
          <ul>
            <li>
              <Link to="/download" aria-label="ダウンロード">
                ダウンロード
              </Link>
            </li>
            <li>
              <Link to="/manual" aria-label="オンライン取説">
                オンライン取説
              </Link>
            </li>
            <li>
              <Link to="/tools/portcheck" aria-label="ポートチェック">
                ポートチェック
              </Link>
            </li>
            <li>
              <Link to="/tools/portcheck-map-e" aria-label="IPv6（MAP-E方式）使用可能ポート確認">
              IPv6使用可能ポート確認
              </Link>
            </li>
            <li>
              <Link to="/contact" aria-label="お問い合わせ">
                お問い合わせ
              </Link>
            </li>
            <li>
              <Link to="/online" aria-label="オンライン商談">
                オンライン商談
              </Link>
            </li>
            <li>
              <Link to="/company" aria-label="会社概要">
                会社概要
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
