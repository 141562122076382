import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="navbar__container">
        <div className="navbar__title">
          <Link to="/" aria-label="ホーム">
            <StaticImage
              layout="constrained"
              src="../images/logo-white-back.png"
              alt="Logo"
              height={60}
            />
          </Link>
        </div>
        <div className="navbar__menu">
          <input type="checkbox" id="drawer-check" className="drawer-hidden"></input>
          <label htmlFor="drawer-check" class="drawer-open">
            <span> </span>
          </label>
          <ul className="navbar__ul_top_level">
            <li className="navbar__li">
              <label htmlFor="menu_bar01">

                お知らせ <span> </span>
                <StaticImage
                  src="../images/arrow-down-sign-to-navigate.png"
                  height={15}
                />
              </label>
              <input type="checkbox" id="menu_bar01" className="accordion" />
              <ul className="navbar__ul_second_level">
                  <li><Link to="/category/news" aria-label="お知らせ">お知らせ</Link></li>
                  <li><Link to="/category/blog" aria-label="技術ブログ">技術ブログ</Link></li>
                  <li><Link to="/category/case" aria-label="技術ブログ">事例紹介</Link></li>
              </ul>
            </li>
            <li className="navbar__li">
              <label htmlFor="menu_bar02">
                製品 <span> </span>
                <StaticImage
                  src="../images/arrow-down-sign-to-navigate.png"
                  height={15}
                />
              </label>
              <input type="checkbox" id="menu_bar02" className="accordion" />
              <ul className="navbar__ul_second_level">
                <li><Link to="/category/camera" aria-label="監視カメラ">監視カメラ</Link></li>
                <li><Link to="/category/recorder" aria-label="レコーダー">レコーダー</Link></li>
                <li><Link to="/category/transfer" aria-label="画像伝送装置">画像伝送装置</Link></li>
                <li><Link to="/category/thermo" aria-label="非接触型測温計">非接触型測温計</Link></li>
                <li><Link to="/category/miscs" aria-label="周辺機器">周辺機器</Link></li>
                <li><Link to="/category/software" aria-label="ソフトウェア">ソフトウェア</Link></li>
              </ul>
            </li>
            <li className="navbar__li">
              <label htmlFor="menu_bar03">
              ダウンロード <span> </span>
                  <StaticImage
                    src="../images/arrow-down-sign-to-navigate.png"
                    height={15}
                  />
                </label>
                <input type="checkbox" id="menu_bar03" className="accordion" />
                <ul className="navbar__ul_second_level">
                  <li><Link to="/download" aria-label="ダウンロード">ダウンロード</Link></li>
                  <li><Link to="/manual" aria-label="オンライン取説">オンライン取説</Link></li>
                  <li><Link to="/tools" aria-label="ツール">ツール</Link></li>

                </ul>
              
            </li>
            <li className="navbar__li navbar__contact">
              <label htmlFor="menu_bar04">
                お問い合わせ <span> </span>
              </label>
              <input type="checkbox" id="menu_bar04" className="accordion" />
              <ul
                className="navbar__ul_second_level"
                id="navbar__ul_second_level_last"
              >
                <li><Link to="/contact" aria-label="お問い合わせ">お問い合わせ</Link></li>
                <li><Link to="/online" aria-label="オンライン商談">オンライン商談</Link></li>
                <li><Link to="/company" aria-label="会社概要">会社概要</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
